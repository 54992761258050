


    import {Component, Vue} from "vue-property-decorator";
import {BASE_API_URL} from "../../../config";
import Axios from "axios";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

@Component({components: {Datepicker} })
export default class ClearActivityLogs extends Vue {
      /**
       *  Public Variables
       */
      public clearActivityLogs = new Array<any>();
      public logLoader: boolean = false;
      public reportLoader: boolean = null;
      public taskClearLogs = new Array<any>();
      public currentClearedLengthOnScroll = 0;
      public currentOtherLengthOnScroll = 0;
      public skip = 0;
      public clearActivityLogsLen = 0;
      public otherClearActivityLogsLen = 0;
      public showClearedLoaderScroll: boolean = false;
      public showOtherLoaderScroll: boolean = false;
      public state: any = {};
      public states: any = {};
      public disable = {};
      public otherLogLoader: boolean = false;

  public async searchByDate(scrolling, type) {
    try {
      /**
       *  set date month and year for left & right side filter
       */
      if (type == "cleared") {
          await this.getClearedReportDateFormatted(scrolling, type)
        }else{
          await this.getOtherClearedReportDateFormatted(scrolling, type)
        }
    } catch (error) {
      console.log(error);
    }
  }

public async getClearedReportDateFormatted(scrolling,type){
  let clearReportStartDate = this.state.from.getDate();
  let clearReportStartMonth = this.state.from.getMonth();
  let clearReportStartYear = this.state.from.getFullYear();

  this.state.from = new Date(clearReportStartYear, clearReportStartMonth, clearReportStartDate);
  let from = this.state.from;

  let clearReportEndDate = this.state.to.getDate();
  let clearReportEndMonth = this.state.to.getMonth();
  let clearReportEndYear = this.state.to.getFullYear();

  this.state.to = new Date(clearReportEndYear, clearReportEndMonth, clearReportEndDate);
  let to = this.state.to;

  await this.fetchClearActivityLogs(scrolling, from, to);
}


public async getOtherClearedReportDateFormatted(scrolling,type){
  let otherClearReportStartDate = this.states.from.getDate();
  let otherClearReportStartMonth = this.states.from.getMonth();
  let otherClearReportStartYear = this.states.from.getFullYear();

  this.states.from = new Date(otherClearReportStartYear, otherClearReportStartMonth, otherClearReportStartDate);
  let from2 = this.states.from;

  let otherClearReportEndDate = this.states.to.getDate();
  let otherClearReportEndMonth = this.states.to.getMonth();
  let otherClearReportEndYear = this.states.to.getFullYear();

  this.states.to = new Date(otherClearReportEndYear, otherClearReportEndMonth, otherClearReportEndDate);
  let to2 = this.states.to;

  await this.fetchOtherReportLogs(scrolling, from2, to2);
}
  /**
   *  Fetch cleared activity logs
   */
  public async fetchClearActivityLogs(scrolling, from, to) {
      this.skip = scrolling ? this.skip + 25 : 0;
      const body = {
      skip: this.skip,
      limit: 25,
      from: from.getTime(),
      to: to.getTime(),
    };

    if (!scrolling) {
      this.logLoader = true;
    }
    try {
      const response = await Axios.get(
        BASE_API_URL + "superAdmin/fetchClearActivityLogs",
        {params: body},
      );
      const resData = response.data.clearActivityLogs;
      this.clearActivityLogs = scrolling ? this.clearActivityLogs.concat(resData) : resData;
      this.currentClearedLengthOnScroll = this.clearActivityLogs.length;
    } catch (error) {
      console.log(error);
    } finally {
      this.showClearedLoaderScroll = false;
      this.logLoader = false;
    }
  }

  /**
   *  Fetch other cleared activity logs
   */
  public async fetchOtherReportLogs(scrolling, from, to) {
    this.skip = scrolling ? this.skip + 25 : 0;

    const body = {
      skip: this.skip,
      limit: 25,
      from: from.getTime(),
      to: to.getTime(),
    };
    if (!scrolling) {
      this.otherLogLoader = true;
    }
    try {
      const response = await Axios.get(
        BASE_API_URL + "superAdmin/fetchOtherClearActivityLogs",
        {params: body},
      );
      const resData = response.data.taskClearLogs;
      this.taskClearLogs = scrolling ? this.taskClearLogs.concat(resData) : resData;
      this.currentOtherLengthOnScroll = this.taskClearLogs.length;
    } catch (error) {
      console.log(error);
    } finally {
      this.otherLogLoader = false;
      this.showOtherLoaderScroll = false;
    }
  }

  /**
   *  Get total logs count
   */
  public async getTotalLogsCount(action) {
    try {
      const body = {
        action: action,
      };
      const response = await Axios.get(
        BASE_API_URL + "superAdmin/fetchActivityLogsCount",
        {
          params: body,
        }
      );

      if (action == 'Cleared'){
        this.clearActivityLogsLen = response.data.activityLogsLen;  
      }else{
        this.otherClearActivityLogsLen = response.data.activityLogsLen;  
      }
    
    } catch (error) {
        console.log(error);
    }
  }

  /**
   *  Showing data on scroll
   */
  public async showClearedDataOnScroll() {
    if (!this.showClearedLoaderScroll && this.currentClearedLengthOnScroll < this.clearActivityLogsLen) {
      this.showClearedLoaderScroll = true;
      await this.searchByDate(true, "cleared");
    }
  }

  public async showDataOnScroll() {
    if (!this.showOtherLoaderScroll && this.currentOtherLengthOnScroll < this.otherClearActivityLogsLen) {
      this.showOtherLoaderScroll = true;
      await this.searchByDate(true, "otherCleared");
    }
  }

  mounted() {
     let date = new Date().getDate();
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    this.state.from = new Date(year, month, date);
    this.states.from = new Date(year, month, date);
    this.state.to = new Date();
    this.states.to = new Date();

    this.disable = {
      from: this.state.from,
      to: this.state.to,
      from2: this.states.from,
      to2: this.states.to
    };

    this.fetchClearActivityLogs(false, this.state.from, this.state.to);
    this.fetchOtherReportLogs(false, this.states.from, this.states.to);
     this.getTotalLogsCount("Cleared");
     this.getTotalLogsCount("otherCleared");
  }

}
